<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="text-h5"><v-icon x-large>mdi-card-account-details-star-outline</v-icon> {{ candidateName }}</div>
                
            </v-col>
            <v-col class="text-right">
                <v-chip label outlined v-text="fullRecruitSrc" />
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="newCandidate.proposedHireDate">
                <div class="overline"><v-icon class="mr-2">mdi-calendar</v-icon>Proposed Start Date</div>
                <div class="ml-8">
                {{ format(parseISO(newCandidate.proposedHireDate), 'MMM do yyyy') }}
                <small style="display: block" class="grey--text mt-n1">{{ formatDistanceToNow(parseISO(newCandidate.proposedHireDate)) }} from today</small>
                </div>
            </v-col>
            <v-col>
                <div class="overline"><v-icon class="mr-2">mdi-office-building</v-icon>Work Location</div>
                <div class="ml-8">
                    {{ candidateWorkLocation }}
                </div>
            </v-col>
        </v-row>

        <v-divider class="mt-4 mb-4"></v-divider>

        <div class="text-h6">Onboarding Tasks</div>

        <template>
            <em>IT Setup</em>
            <v-row>
                
                <v-col v-if="newCandidate.onboardingTasks.it.desktopSetup" xs="12" class="pt-0">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-desktop-tower-monitor</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="overline">Desktop</div>
                            <div v-if="newCandidate.onboardingTasks.it.desktopSetup === 'existing' && newCandidate.onboardingTasks.it.assignedDesktop && !!Object.keys(newCandidate.onboardingTasks.it.assignedDesktop).length && newCandidate.onboardingTasks.it.assignedDesktop.value !== 'other'">
                                    {{ newCandidate.onboardingTasks.it.assignedDesktop.name }}
                                    <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.assignedDesktop.spec }}</v-list-item-subtitle>
                            </div>
                            <div v-else-if="newCandidate.onboardingTasks.it.desktopSetup === 'existing' && newCandidate.onboardingTasks.it.otherDesktop">
                                    {{ newCandidate.onboardingTasks.it.otherDesktop.name }}
                                    <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.otherDesktop.spec }}</v-list-item-subtitle>
                            </div>
                            <div v-else-if="newCandidate.onboardingTasks.it.desktopSetup === 'new' && newCandidate.onboardingTasks.it.newDesktop">
                                New 
                                <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.newDesktop.name }}</v-list-item-subtitle>
                            </div>
                            <div v-else>
                                {{ newCandidate.onboardingTasks.it.desktopSetup | capitalize }}
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col v-if="newCandidate.onboardingTasks.it.laptopSetup" xs="12" class="pt-0">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-laptop</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="overline">Laptop</div>
                            <div v-if="newCandidate.onboardingTasks.it.laptopSetup === 'existing' && newCandidate.onboardingTasks.it.assignedLaptop">
                                {{ newCandidate.onboardingTasks.it.assignedLaptop.name }}
                                <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.assignedLaptop.spec }}</v-list-item-subtitle>
                            </div>
                            <div v-else-if="newCandidate.onboardingTasks.it.laptopSetup === 'new' && newCandidate.onboardingTasks.it.newLaptop">
                                New 
                                <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.newLaptop.name }} Laptop</v-list-item-subtitle>
                            </div>
                            <div v-else>
                                {{ newCandidate.onboardingTasks.it.laptopSetup | capitalize }}
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col v-if="newCandidate.onboardingTasks.it.printerSetup" xs="12" class="pt-0">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-printer</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="overline">Printer</div>
                            <div v-if="newCandidate.onboardingTasks.it.printerSetup === 'existing' && newCandidate.onboardingTasks.it.assignedPrinter && !!Object.keys(newCandidate.onboardingTasks.it.assignedPrinter).length && newCandidate.onboardingTasks.it.assignedPrinter.value !== 'other'">
                                {{ newCandidate.onboardingTasks.it.assignedPrinter.name }}
                                <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.assignedPrinter.spec }}</v-list-item-subtitle>
                            </div>
                            <div v-else-if="newCandidate.onboardingTasks.it.printerSetup === 'existing' && newCandidate.onboardingTasks.it.otherPrinter">
                                {{ newCandidate.onboardingTasks.it.otherPrinter.name }}
                                <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.otherPrinter.spec }}</v-list-item-subtitle>
                            </div>
                            <div v-else>
                                {{ newCandidate.onboardingTasks.it.printerSetup | capitalize }}
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col v-if="newCandidate.onboardingTasks.it.cellPhoneSetup" xs="12" class="pt-0">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-cellphone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="overline">Cellphone</div>
                                <div v-if="newCandidate.onboardingTasks.it.cellPhoneSetup === 'new' && newCandidate.onboardingTasks.it.newCellPhone">
                                New 
                                    <v-list-item-subtitle>{{ newCandidate.onboardingTasks.it.newCellPhone.name }} Cellphone</v-list-item-subtitle>
                                </div>
                                <div v-else>
                                    {{ newCandidate.onboardingTasks.it.cellPhoneSetup | capitalize }}
                                </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </template>

        <template v-if="newCandidate.onboardingTasks.ops.telecom">
            <em>Operations</em>
            <v-row>
                <v-col>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-deskphone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="overline">Telecom</div>
                            {{ newCandidate.onboardingTasks.ops.telecom | capitalize }}
                            <v-list-item-subtitle v-if="newCandidate.onboardingTasks.ops.telecom == 'existing'">
                                {{ newCandidate.onboardingTasks.ops.telecomPhoneLine }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        
                    </v-list-item>
                </v-col>
            </v-row>
        </template>

        
        <template v-if="newCandidate.onboardingTasks.it.accounts">
            <em>Accounts</em>
            <v-row>
                <v-col>
                    <v-list-item v-for="(account, i) in newCandidate.onboardingTasks.it.accounts" :key="i">
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="overline">{{ i == 'mc' ? 'MC Domain' : 'VDI Account' }}</div>
                            <div>{{ account ? 'Yes' : 'No' }}</div>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </template>

        <template v-if="newCandidate.onboardingTasks.it.systemAccess.length">
            <v-row>
                <v-col>
                    <em class="pl-4">Systems Access</em>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-application</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            {{ newCandidate.onboardingTasks.it.systemAccess.join(', ') }}
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </template>
        
        <template>
            <v-row>
                <v-col>
                    <em>Communications</em>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="mr-2">mdi-email-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            {{ mailingLists.map(x => x.name).join(', ') }}
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </template>

        <!-- <div class="text-h6 mt-3 mb-3">{{ newCandidate.hireType }} Hire Timeline</div>

        <v-stepper v-model="hireStep" alt-labels>
            <v-stepper-header>
                <v-stepper-step :complete="true" step="1" class="subtitle-2">
                    Select Candidate
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="hireStep > 2" step="2" class="subtitle-2">
                    Finance Validation
                    <small>
                        <div class="mt-n1 caption grey--text">~{{ futureDate(2) }}</div>
                    </small>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" :complete="hireStep > 3" class="subtitle-2">
                    HR Clearance
                    <small>
                        <div class="mt-n1 caption grey--text">~{{ futureDate(8) }}</div>
                    </small>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="4" class="subtitle-2">
                    Onboarding
                    <small>
                        <div class="mt-n1 caption grey--text">~{{ futureDate(30) }}</div>
                    </small>
                </v-stepper-step>
            </v-stepper-header>
        </v-stepper> -->
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { formatDistanceToNow, parseISO, format, addBusinessDays } from 'date-fns';

    export default {
        name: 'CandidateSummary',
        computed: {
            ...mapGetters({
                candidateName: 'population/getNewCandidateName',
                newCandidate: 'population/getNewCandidate',
                candidateWorkLocation: 'population/getNewCandidateWorkLocation',
                step: 'getStep'
            }),
            fullRecruitSrc() {
                if (!this.newCandidate.recruitSrc) return;
                const src = this.recruitSrcOptions.find(x => x.value === this.newCandidate.recruitSrc).text;
                switch (this.newCandidate.recruitSrc) {
                    case 'org':
                        if (!this.newCandidate.recruitSrcDept) return;
                        return `${src} from ${this.newCandidate.recruitSrcDept.name}`;
                    case 'dept':
                        if (!this.newCandidate.recruitSrcDiv) return;
                        return `${src} from ${this.divisionStr(this.newCandidate.recruitSrcDiv)}`;
                    default:
                        return `${src}`;
                }
            },
            mailingLists() {
                return [...this.newCandidate.onboardingTasks.commSubs];
            },
            hireStep() {
                switch (this.newCandidate.status) {
                    case 'Finance Validation':
                        return 2;
                    case 'HR Clearance':
                        return 3;
                    case 'Onboarding':
                        return 4;
                }
                return 1;
            }
        },
        methods: {
            parseISO: parseISO,
            formatDistanceToNow: formatDistanceToNow,
            format: format,
            futureDate(days) {
                return format(addBusinessDays(new Date(), days), 'MMM do');
            }
        },
        data() {
            return {
            }
        }
    }
</script>