<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        v-if="newHireRequest.employeeType"
    >
        <v-tabs v-model="tab">
            <v-tab key="position">Position Summary</v-tab>
            <v-tab key="specialIndicators" v-if="!hideFields.includes('specIndicators')">Special Indicators</v-tab>
            <v-tab key="financial" v-if="showFinancial && newHireRequest.employeeType.name !== 'Short Term Visitor - NYP'">Financial<v-icon v-if="finNeedsApproval" color="orange" class="ml-1">mdi-alert-circle</v-icon></v-tab>
            <v-tab key="addressContact" v-if="showFields.includes('addressContact')">Emergency Contact</v-tab>
            <v-tab key="roleActivity" v-if="showFields.includes('roleActivity')">Roles & Activities</v-tab>
            <v-tab key="it" v-if="showFields.includes('mc')">IT</v-tab>
            <v-tab key="attachments">Attachments<v-icon v-if="needsAttachment" color="orange" class="ml-1">mdi-alert-circle</v-icon></v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-form
                ref="positionForm"
                v-model="formValid.position"
                class="px-4"
                lazy-validation
            >
                <v-tab-item key="position">
                    <v-row>
                        <v-col>
                            <v-select
                                class="mt-2"
                                v-model="newHireRequest.division"
                                :items="authorizedDivisionList"
                                item-text="name"
                                label="Division"
                                :rules="$rules.required"
                                return-object
                                ref="position.division"
                                @change="getHiringManagers($event)"
                            ></v-select>
                        </v-col>
                        <v-col v-if="showHiringManagerField()">
                            <v-select
                                class="mt-2"
                                v-model="newHireRequest.hiringManager"
                                :items="hiringManagerList"
                                item-text="displayName"
                                label="Hiring Manager"
                                return-object
                                ref="position.hiringManager"
                                :loading="loadingHiringManager"
                                :disabled="disableHiringManagerField"
                                hint="Only hiring managers and division approvers can edit this field after ticket creation."
                                persistent-hint
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    
                    <v-text-field
                        v-model="newHireRequest.title"
                        label="Title"
                        :rules="$rules.required"
                        required
                        :readonly="resReadOnly"
                        v-if="!hideFields.includes('title')"
                    />
                    <v-row>
                        <v-col> 
                            <v-textarea
                                label="Description"
                                v-model="newHireRequest.description"
                                class="mt-2"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="!hideFields.includes('zeroSalary') && !hideFields.includes('billingProvider')">
                        <v-checkbox
                            v-model="newHireRequest.zeroSalary"
                            v-if="!hideFields.includes('zeroSalary')"
                            label="Zero-salary Employee"
                            class="ml-2"
                            @change="chgZeroSalary($event)"
                            hide-details
                        ></v-checkbox>
                        <v-checkbox
                            v-model="newHireRequest.billingProvider"
                            v-if="!hideFields.includes('billingProvider')"
                            label="Billing provider"
                            class="ml-2"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                    <v-row v-if="!hideFields.includes('hireType')">
                        <v-col>
                            <v-select
                                v-model="newHireRequest.hireType"
                                :items="hireTypeOptions"
                                label="Hire Type"
                                return-object
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-select
                                v-model="newHireRequest.researchPosition"
                                :items="visitorOptions.researchPosition"
                                label="Is this a research position?"
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-if="newHireRequest.hireType === 'New Position' && !hideFields.includes('hireType')"
                        v-model="newHireRequest.justification"
                        :rules="$rules.required"
                        required
                        label="New Position Justification"
                        class="mb-2"
                    />
                    <v-row
                        v-if="newHireRequest.hireType === 'Replacement' && !hideFields.includes('hireType')"
                        no-gutters
                    >
                        <v-col class="mr-8">
                            <v-text-field
                                v-model="newHireRequest.replacement_uni"
                                :rules="$rules.uni"
                                validate-on-blur
                                required
                                label="Replace Employee UNI"
                                @change="searchUni"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="newHireRequest.replacement_name"
                                :rules="$rules.required"
                                required
                                label="Replace Employee Name"
                            />
                        </v-col>
                    </v-row>
                    <!--
                    <v-row
                        v-if="(newHireRequest.candidateSearch === 'Known candidate' && !hideFields.includes('candidateSearch')) || newHireRequest.employeeType.name === 'UNI Request'"
                    >
                    -->
                    <v-row
                        v-if="['UNI Request', 'Resident', 'Visitor/Observer', 'Short Term Visitor - NYP'].indexOf(newHireRequest.employeeType.name) > -1"
                    >
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                v-model="newHireRequest.knownCandidate.firstName"
                                label="First Name"
                                :rules="$rules.required"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                v-model="newHireRequest.knownCandidate.middleName"
                                label="Middle Name"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                v-model="newHireRequest.knownCandidate.lastName"
                                label="Last Name"
                                :rules="$rules.required"
                                required
                            />
                        </v-col>
                    </v-row>
                    <!--
                    <v-text-field
                        v-if="newHireRequest.candidateSearch === 'Known candidate' || newHireRequest.employeeType.name === 'UNI Request'"
                        v-model="newHireRequest.knownCandidate.contactInfo"
                        label="Contact Info"
                        :rules="$rules.required"
                        required
                    />
                    -->
                    <v-row>
                        <v-col v-if="showFields.includes('contactInfo')" cols="12" :md="showFields.includes('hireDate') ? 6 : 12">
                            <v-text-field
                                v-model="newHireRequest.knownCandidate.contactInfo"
                                label="Contact Info"
                                :rules="$rules.required"
                                required
                            />
                        </v-col>
                        <v-col v-if="showFields.includes('hireDate')" cols="12" :md="showFields.includes('contactInfo') ? 6 : 12">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                max-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computedHireDateFormatted"
                                        label="Hire date"
                                        prepend-icon="event"
                                        :rules="$rules.required"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="newHireRequest.hireDate"
                                    no-title
                                    @input="menu = false"
                                ></v-date-picker><!-- :min="new Date().toISOString().split('T')[0]" -->
                            </v-menu>
                        </v-col>
                    </v-row>               
                    
                    <v-row>
                        <v-col cols="12" v-if="showFields.includes('personalEmail')" :md="showFields.includes('uni') ? 6 : 12">
                            <v-text-field
                                v-model="newHireRequest.knownCandidate.personalEmail"
                                label="Personal Email"
                                :rules="$rules.required"
                                required
                            />
                        </v-col>
                        <v-col cols="12" v-if="showFields.includes('uni')" :md="showFields.includes('personalEmail') ? 6 : 12">
                            <v-text-field
                                v-model="newHireRequest.knownCandidate.uni"
                                label="UNI"
                                :rules="$rules.required"
                                required
                            />
                        </v-col>
                    </v-row>
                    
                    <template
                        v-if="['Visitor/Observer', 'Short Term Visitor - NYP'].indexOf(newHireRequest.employeeType.name) > -1"
                    >
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="newHireRequest.visitor.homeInstitution"
                                    label="Home Institution"
                                    :rules="$rules.required"
                                    hide-details
                                    required
                                />
                            </v-col>
                            
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="newHireRequest.visitor.type"
                                    :items="visitorOptions.visitorType"
                                    :rules="$rules.required"
                                    label="Visitor Type"
                                    disabled
                                    required
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="newHireRequest.researchPosition"
                                    :items="visitorOptions.researchPosition"
                                    label="Is this a research position?"
                                    :rules="$rules.required"
                                    clearable
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-select
                                    v-model="newHireRequest.visitor.intlDomestic"
                                    :items="visitorOptions.intlOrDomestic"
                                    label="International or Domestic Visitor"
                                    :rules="$rules.required"
                                    required
                                ></v-select>
                            </v-col>
                            <!-- <v-col>
                                <v-select
                                    v-model="newHireRequest.visitor.age"
                                    :items="visitorOptions.visitorAge"
                                    :rules="$rules.required"
                                    label="Visitor Age"
                                    required
                                ></v-select>
                            </v-col> -->
                        </v-row>
                        
                        <!-- 
                        <v-textarea
                            v-model="newHireRequest.visitor.relation"
                            label="Name(s) and Department(s) of family members employed at CU"
                            hide-details
                        ></v-textarea>-->
                        
                    </template>
                    <LocationInput
                        v-if="showFields.includes('location')"
                        :key="locationKey"
                        @locationChange="setLocation"
                        :location="newHireRequest.location || {}"
                        :emitOnEnter="false"
                        ref="locationInput"
                        hide-buttons
                    />
                </v-tab-item>
            </v-form>
            <v-form
                ref="specIndicatorsForm"
                v-model="formValid.spec"
                class="px-4"
                lazy-validation
            >
                <v-tab-item
                    key="specialIndicators"
                    v-if="!hideFields.includes('specIndicators')"
                    eager
                >
                    <v-checkbox
                        :label="noSpecialIndicators"
                        :value="noSpecialIndicators"
                        v-model="newHireRequest.specialIndicators"
                        @change="handleSpecialIndicator"
                        :rules="$rules.nonZeroLength"
                        class="mt-2"
                        hide-details
                    ></v-checkbox>
                    <v-row
                        v-for="(indicator, i) in specialIndicators"
                        :key="i"
                        class="ma-0 pa-0"
                    >
                        <v-checkbox
                            :label="indicator"
                            v-model="newHireRequest.specialIndicators"
                            @change="handleSpecialIndicator"
                            :value="indicator"
                            :rules="$rules.nonZeroLength"
                            class="ma-0"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                </v-tab-item>
            </v-form>
            <v-form
                ref="finForm"
                v-model="formValid.finance"
                class="px-4"
                lazy-validation
            >
                <v-tab-item
                    key="financial"
                    v-if="showFinancial && !newHireRequest.employeeType.name == 'Short Term Visitor - NYP'"
                    eager
                >
                    <v-row v-if="!hideFields.includes('salary')">
                        <v-col cols="12" sm="6" md="6" v-if="!hideFields.includes('minSalary')">
                            <v-currency-field
                                v-model="newHireRequest.finance.minSalary"
                                label="Min. Salary"
                                prefix="$"
                                :disabled="lockedFields.includes('minSalary')"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="!hideFields.includes('maxSalary')">
                            <v-currency-field
                                v-model="newHireRequest.finance.maxSalary"
                                label="Max Salary"
                                prefix="$"
                                :disabled="lockedFields.includes('maxSalary')"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="!hideFields.includes('passthru')">
                        <v-checkbox
                            v-model="newHireRequest.finance.passthru"
                            label="Passthru"
                            class="ml-2"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-text-field
                                v-model="newHireRequest.finance.arcDept"
                                label="Dept"
                                :rules="$rules.required"
                                required
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-text-field
                                v-model="newHireRequest.finance.arcProject"
                                label="Project"
                                :rules="$rules.required"
                                required
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-text-field
                                v-model="newHireRequest.finance.arcInitiative"
                                label="Initiative"
                                :rules="$rules.required"
                                required
                                hide-details
                            />
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-text-field
                                v-model="newHireRequest.finance.arcSegment"
                                label="Segment"
                                :rules="$rules.required"
                                required
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="newHireRequest.finance.combocode"
                        v-if="!hideFields.includes('combocode')"
                        label="Combo Code"
                        hide-details
                    />
                    <v-row v-if="!hideFields.includes('grade')">
                        <v-col cols="12" sm="6" md="6">
                                <v-select
                                v-model="newHireRequest.finance.gradeType"
                                :items="gradeOptions"
                                label="Grade"
                                return-object
                                hide-details
                                clearable
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="newHireRequest.finance.gradeType === 'Officer'">
                                <v-select
                                v-model="newHireRequest.finance.gradeLevel"
                                :items="gradeOfficerOptions"
                                label="Level"
                                :rules="$rules.required"
                                return-object
                                hide-details
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="newHireRequest.finance.gradeType === 'SSA'">
                                <v-select
                                v-model="newHireRequest.finance.gradeLevel"
                                :items="gradeSSAOptions"
                                label="Level"
                                :rules="$rules.required"
                                return-object
                                hide-details
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="newHireRequest.finance.chartStringNotes"
                                label="Chart String Notes"
                                outlined
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-form>
            <v-form
                ref="addressContactForm"
                v-model="formValid.addressContact"
                lazy-validation
            >
                <v-tab-item
                    key="addressContact"
                    eager
                    v-if="showFields.includes('addressContact')"
                >
                <!-- <h2 class="mt-2">Visitor Address</h2>
                <v-text-field
                        v-model="newHireRequest.visitor.location.address"
                        label="Address"
                        :rules="$rules.required"
                        hide-details
                        required
                        class="mb-2"
                    />
                    <v-text-field
                        v-model="newHireRequest.visitor.location.address2"
                        label="Apt/Suite/Other"
                        hide-details
                        class="mb-2"
                    />
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="newHireRequest.visitor.location.city"
                                label="City"
                                :rules="$rules.required"
                                hide-details
                                required
                                class="mb-2"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="newHireRequest.visitor.location.state"
                                label="State"
                                :rules="$rules.required"
                                hide-details
                                required
                                class="mb-2"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="newHireRequest.visitor.location.zipCode"
                                label="Zip Code"
                                :rules="$rules.required"
                                hide-details
                                required
                                class="mb-2"
                            />
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="newHireRequest.visitor.location.country"
                        label="Country (if outside US)"
                        hide-details
                        class="mb-2"
                    /> -->
                    <h2 class="mt-2">Emergency Contact</h2>
                    <v-text-field
                        v-model="newHireRequest.visitor.emergencyContact.name"
                        label="Emergency Contact Name"
                        :rules="$rules.required"
                        required
                    />
                    <v-text-field
                        v-model="newHireRequest.visitor.emergencyContact.phone"
                        label="Emergency Contact Phone Number"
                        :rules="$rules.required"
                        required
                    />
                    <v-text-field
                        v-model="newHireRequest.visitor.emergencyContact.relationship"
                        label="Emergency Contact Relationship"
                        :rules="$rules.required"
                        required
                    />
                </v-tab-item>
            </v-form>
            <v-form
                ref="roleActivityForm"
                v-model="formValid.roleActivity"
                lazy-validation
            >
                <v-tab-item
                    key="roleActivity"
                    eager
                    v-if="showFields.includes('roleActivity')"
                >
                    <v-select
                        v-model="newHireRequest.visitor.department"
                        :items="departmentList"
                        item-text="name"
                        label="Department"
                        :rules="$rules.required"
                        return-object
                    />
                    <!-- <v-textarea
                        v-model="newHireRequest.visitor.role"
                        label="Visitor Role and Activities"
                        :rules="$rules.required"
                        required
                        hide-details
                    ></v-textarea>
                    <v-text-field
                        v-model="newHireRequest.visitor.hours"
                        label="Est. Hours Per Week"
                        :rules="$rules.required"
                        required
                    /> -->
                    <v-row>
                        <v-col cols="6">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                max-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computedStartDateFormatted"
                                        label="Start date"
                                        prepend-icon="event"
                                        :rules="$rules.required"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="newHireRequest.visitor.startDate"
                                    no-title
                                    @input="menu = false"
                                ></v-date-picker><!-- :min="new Date().toISOString().split('T')[0]" -->
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                max-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computedEndDateFormatted"
                                        label="End date"
                                        prepend-icon="event"
                                        :rules="$rules.required"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="newHireRequest.visitor.endDate"
                                    no-title
                                    @input="menu2 = false"
                                ></v-date-picker><!-- :min="new Date().toISOString().split('T')[0]" -->
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-textarea
                        v-model="newHireRequest.visitor.observingLocation"
                        label="Observing Location"
                        :rules="$rules.required"
                        required
                        hide-details
                    ></v-textarea>
                    <v-text-field
                        v-model="newHireRequest.visitor.supervisor.name"
                        label="Supervisor Name"
                        :rules="$rules.required"
                        required
                    />
                    <!-- <v-text-field
                        v-model="newHireRequest.visitor.supervisor.title"
                        label="Supervisor Title"
                        :rules="$rules.required"
                        required
                    />
                    <v-text-field
                        v-model="newHireRequest.visitor.supervisor.phone"
                        label="Supervisor Phone"
                        :rules="$rules.required"
                        required
                    /> -->
                    <!-- <v-row>
                        <v-col cols="6">
                            <v-select
                                class="mt-2"
                                v-model="newHireRequest.visitor.nypSpace"
                                :items="visitorOptions.nypSpace"
                                label="NYP Space"
                                :rules="$rules.required"
                            ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                class="mt-2"
                                v-model="newHireRequest.visitor.backgroundCheck"
                                :items="visitorOptions.bgCheck"
                                label="Background Check Initiated"
                                :rules="$rules.required"
                            ></v-select>
                        </v-col>
                    </v-row> -->
                </v-tab-item>
            </v-form>
            <v-form
                ref="itForm"
                v-model="formValid.itForm"
                lazy-validation
            >
                <v-tab-item
                    key="it"
                    eager
                    v-if="showFields.includes('mc')"
                >
                    <v-col cols="6">
                        <v-select
                            class="mt-2"
                            v-model="newHireRequest.it.mc"
                            :items="mcExchangeOptions"
                            item-text="name"
                            label="MC Exchange Account"
                            :rules="$rules.required"
                        ></v-select>
                    </v-col>
                </v-tab-item>
            </v-form>
            <v-form
                ref="attachmentForm"
                v-model="formValid.attachment"
                lazy-validation
            >
                <v-tab-item
                    key="attachments"
                    eager
                >
                    <AttachmentAlert
                        :attachments="newHireRequest.attachments.jira"
                        :pendingAttachments="newHireRequest.pendingAttachments"
                        :attachmentTypes="hrTicketType.attachments"
                        class="mt-4"
                        ref="attAlert"
                        @validityChange="validAttach = arguments[0]"
                    />
                    <AttachmentList
                        :attachments="newHireRequest.attachments.jira"
                        :item="{key: newHireRequest.editRequest}"
                        :attachmentTypes="hrTicketType.attachments"
                        :isValidPending="validAttach"
                        @deleteAttachment="updateJiraAttachments"
                        @changeAttachmentType="updateJiraAttachments"
                        @reload="$store.dispatch('population/loadNewHires')"
                    />
                    <AttachmentInput
                        :attachmentTypes="hrTicketType.attachments"
                        @pendingAttachments="handlePendingAttachments"
                        @validityChange="$emit('validityChange', true)"
                    />
                </v-tab-item>
            </v-form>
        </v-tabs-items>
    </v-form>
</template>
<script>
import axios from 'axios';
import LocationInput from '@/components/ops/LocationInput';
import AttachmentList from '@/components/population/attachments/AttachmentList';
import AttachmentInput from '@/components/population/attachments/AttachmentInput';
import AttachmentAlert from '@/components/population/attachments/AttachmentAlert';

import { mapGetters, mapActions } from "vuex";

export default {
    name: "NewHireForm",
    components: {
        LocationInput,
        AttachmentList,
        AttachmentInput,
        AttachmentAlert
    },
    data() {
        return {
            valid: true,
            // division: null,
            hireTypeOptions: ['New Position', 'Replacement', 'Temporary'],
            //candidateSearchOptions: ['Open', 'Known candidate'],
            gradeOptions: ['Officer', 'SSA'],
            gradeOfficerOptions: ['103', '104', '105', '106', '107', '108'],
            gradeSSAOptions: ['2', '3', '4', '5', '6', '7', '8', '8a', '8b'],
            tab: null,
            noSpecialIndicators: 'There are no special indications',
            finNeedsApproval: false,
            visitorOptions: {
                nypSpace: ['Yes', 'No'],
                bgCheck: ['Yes', 'No', 'N/A'],
                visitorType: ['Clinical', 'Research', 'Admin', 'Student Intern-Research', 'NYP Attending'],
                visitorAge: ['Age 14-18', 'Age over 18'],
                intlOrDomestic: ['International', 'Domestic'],
                researchPosition: ['Yes', 'No']
            },
            mcExchangeOptions: [
                {
                    'name': 'Yes',
                    'value': "MC Exchange Account"
                },
                {
                    'name': 'No',
                    'value': 'No'
                }
            ],
            formValid:{
                position: true,
                spec: true,
                finance: true,
                attachment: true,
                addressContact: true,
                roleActivity: true,
                itForm: true
            },
            hideFields: [],
            needsAttachment: false,
            hasAttachment: {},
            showFields: [],
            originalAttachment: {},
            validAttach: true,
            locationKey: 0,
            menu: false,
            menu2: false,
            resReadOnly: false,
            loadingHiringManager: false,
        };
    },
    computed: {
        ...mapGetters({
            authorizedDivisionList: 'population/getAuthorizedDivisionList',
            specialIndicators: 'population/getSpecialIndicators',
            getNewHireRequest: 'population/getNewHireRequest',
            divPerms: 'population/getDivPerms',
            departmentList: 'population/getDepartmentList',
            hiringManagerList: 'population/getHiringManagerList',
            currentUser: 'user'
        }),
        newHireRequest: {
            get() {
                return this.getNewHireRequest;
            },
            set(payload) {
                return this.setNewHireRequest(payload);
            }
        },
        lockedFields() {
            let lockedFields = [];

            if (this.newHireRequest.status) {
                for (let check of this.hrLockedFields) {
                    if (check.statusNotIn && !check.statusNotIn.includes(this.newHireRequest.status)) {
                        lockedFields = lockedFields.concat(check.lockFields)
                    }
                }
            }

            return lockedFields;
        },
        isDivisionApprover() {
            if (this.newHireRequest.division) {
                if (this.divPerms.approvers.some(x => x.id === this.newHireRequest.division.id)) {
                    return true;
                }
            }
            return false;
        },
        isHiringManager() {
            if (this.newHireRequest.hiringManager) {
                let hiringManager = this.newHireRequest.hiringManager.uni;
                let currentUser = this.currentUser.username;
                let currentHiringManager = this.newHireRequest.currentHiringManager;

                if (hiringManager === currentUser) {
                    return true;
                }
                
                if (Object.keys(currentHiringManager).length !== 0) {
                    if (hiringManager !== currentUser) {
                        if (this.newHireRequest.editRequest) {
                            if (currentHiringManager.uni == currentUser) {
                                return true;
                            }
                        }
                    } 
                }
            }
            return false;
        },
        showFinancial() {
            if (this.employeeType && this.employeeType.name === 'UNI Request') {
                return true;
            }
            
            if (this.isDivisionApprover) {
                return true;
            }
            
            if (this.isHiringManager) {
                return true;
            }
            
            return false;
        },
        employeeType() {
            return this.newHireRequest.employeeType;
        },
        hrTicketType() {
            if (this.employeeType.hr_ticket_type) {
                return this.employeeType.hr_ticket_type;
            }
            return {attachments: {}};
        },
        division() {
            return this.newHireRequest.division
        },
        computedHireDateFormatted() {
            return this.formatDate(this.newHireRequest.hireDate);
        },
        computedStartDateFormatted() {
            return this.formatDate(this.newHireRequest.visitor.startDate);
        },
        computedEndDateFormatted() {
            return this.formatDate(this.newHireRequest.visitor.endDate);
        },
        nypSpace(){
            return this.newHireRequest.visitor.nypSpace
        },
        disableHiringManagerField() {
            if (this.loadingHiringManager) return true;
            
            if (this.newHireRequest.editRequest) {
                if (this.isHiringManager || this.isDivisionApprover) {
                    return false;
                }
                
                if (!this.isHiringManager || !this.isDivisionApprover) {
                    return true;
                }

            }

            return false;
        }
    },
    methods: {
        ...mapActions({
            setNewHireRequest: 'population/setNewHireRequest'
        }),
        validate() {
            return this.$refs.form.validate();
        },
        async resetForm(finNeedsApproval = false) {
            await this.$store.dispatch('population/resetNewHireRequest');
            this.tab = null;
            this.valid = true;
            this.needsAttachment = false;
            this.finNeedsApproval = finNeedsApproval;
            this.hasAttachment = {};
            this.originalAttachment = {};
        },
        handleSpecialIndicator(val) {
            if(val.includes(this.noSpecialIndicators)) {
                this.newHireRequest.specialIndicators = [this.noSpecialIndicators]
            }
        },
        resetFinancial() {
            this.newHireRequest.finance = {};
        },
        setHideFields(l) {
            this.hideFields = l;
        },
        addHideField(f) {
            this.hideFields = this.hideFields.filter(x => x !== f).concat([f]);
        },
        removeHideField(f) {
            this.hideFields = this.hideFields.filter(x => x !== f);
        },
        setShowFields(l) {
            this.showFields = l;
        },
        resetFields() {
            this.hideFields = [];
            this.showFields = [];
        },
        setLocation(loc) {
            this.newHireRequest.location = loc;
        },
        async searchUni() {
            if (this.newHireRequest.replacement_uni) {
                const response = await axios.get(`/population/search_uni/${this.newHireRequest.replacement_uni}/?search_employees=true`);
                if (!response.data.noResults) {
                    this.$set(this.newHireRequest, 'replacement_name', `${response.data.firstName} ${response.data.lastName}`);
                }
            }
        },
        chgZeroSalary(val) {
            val ? this.addHideField('salary') : this.removeHideField('salary');
        },

        /*
        ATTACHMENT STUFF
        */
        handlePendingAttachments(payload) {
            this.newHireRequest.pendingAttachments = payload;
        },
        async updateJiraAttachments(payload) {
            this.newHireRequest.attachments.jira = payload.jiraAttachments;
            await this.$nextTick(() => {
                this.$refs.attAlert.validate();
            });
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        autoFillResident(div) {
            let d = null;
            let config = this.employeeType.hr_ticket_type.config;

            for (let i = 0; i < config.payload.length; i++){
                if (config.payload[i].division.name === div.name) {
                    d = config.payload[i];
                    break;
                }
            }
            if (d === null) {
                this.clearResident();
                return
            }
            for (let j = 0; j < d.data.length; j++){
                let { field, payload, parent } = d.data[j];
                if (parent !== null){
                    this.newHireRequest[parent][field] = payload
                } else {
                    this.newHireRequest[field] = payload
                }
            }
            // Set title to readonly
            this.resReadOnly = true;
            // Rerender location component
            if (this.newHireRequest.location) this.locationKey = Math.round(Math.random() * 1000);
        },
        autoFillShortTermVisitor() {
            let config = this.employeeType.hr_ticket_type.config;

            let d = config.payload[0]

            for (let j = 0; j < d.data.length; j++){
                let { field, payload, parent } = d.data[j];
                if (parent !== null){
                    this.newHireRequest[parent][field] = payload
                } else {
                    this.newHireRequest[field] = payload
                }
            }
            // Set title to readonly
            this.resReadOnly = true;
            // Rerender location component
            if (this.newHireRequest.location) this.locationKey = Math.round(Math.random() * 1000);
        },
        clearResident() {
            this.newHireRequest.title = '';
            this.newHireRequest.location = {};
            this.newHireRequest.knownCandidate = {};
            this.resReadOnly = false;
            this.locationKey = Math.round(Math.random() * 1000);
        },
        clearShortTermVisitor() {
            this.newHireRequest.title = '';
            this.resReadOnly = false;
            this.locationKey = Math.round(Math.random() * 1000);
        },
        async getHiringManagers(division) {
            this.loadingHiringManager = true;
            await this.$store.dispatch('population/loadHiringManagerList', division)
            this.loadingHiringManager = false;
        },
        showHiringManagerField() {
            if (this.newHireRequest.division) return true;
            
            // other conditions here
            return false;
        },
    },

    watch: {
        valid(val) {
            this.$emit('validityChange', val);
        },
        showFinancial(val) {
            if (!val) this.resetFinancial();
        },
        formValid: {
            handler(val){
                for(let x in val){
                    if(!val[x]){
                        return;
                    }
                }
                this.$emit('validityChange', true);
            },
            deep: true
        },
        async nypSpace(val){
            if (!val) return;
            await this.$nextTick(() => {
                this.$refs.attAlert.validate();
            });
        },
        employeeType(newVal, oldVal){
            if(!newVal) return;

            if (oldVal == newVal) return;
    
            if (this.newHireRequest.editRequest ){
                if(newVal.name !== this.hasAttachment.employee_type){
                    if(newVal.name == this.originalAttachment.employee_type){
                        this.hasAttachment = this.originalAttachment;
                    }else{
                        this.hasAttachment = this.newHireRequest.employeeType.attachments || {};
                    }
                }
            }
            if (newVal.name == 'Resident' && this.newHireRequest.division && Object.keys(this.newHireRequest.division).length !== 0) {
                this.autoFillResident(this.newHireRequest.division);
            }
            if (oldVal && oldVal.name == 'Resident') {
                console.log('im in here');
                this.clearResident();
            }

            if (newVal.name == 'Short Term Visitor - NYP') {
                console.log('im in here 2')
                this.autoFillShortTermVisitor();
            }

            if (oldVal && oldVal.name == 'Short Term Visitor - NYP') {
                console.log('im in here 3')
                this.clearShortTermVisitor();
            }
        },
        async division(val){
            if (!val) return;
            if (this.employeeType.name === 'Resident' && Object.keys(this.employeeType.hr_ticket_type.config).length !== 0 ){
                this.autoFillResident(val);
            }
        },
    },

    mounted() {
        this.$store.dispatch('population/initNewHireForm');
    }
};
</script>