import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v("Position Information")]),_c(VExpansionPanelContent,[(_vm.issueChecklist.position_info.cu_uni)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.cu_uni,"readonly":"","label":"CU UNI"}})],1)],1):_vm._e(),(_vm.issueChecklist.position_info.cwid)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.cwid,"readonly":"","label":"CWID"}})],1)],1):_vm._e(),(_vm.issueChecklist.position_info.requisition_number)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.requisition_number,"readonly":"","label":"Requisition Number"}})],1)],1):_vm._e(),(_vm.issueChecklist.position_info.talent_link_number)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.talent_link_number,"readonly":"","label":"Talent Link JD Number"}})],1)],1):_vm._e(),(_vm.issueChecklist.position_info.hrcules_number)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.hrcules_number,"readonly":"","label":"HRCULES Number"}})],1)],1):_vm._e(),(_vm.issueChecklist.position_info.interfolio_number)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.interfolio_number,"readonly":"","label":"Interfolio Number"}})],1)],1):_vm._e(),(_vm.issueChecklist.position_info.candidate_link)?_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"value":_vm.issueChecklist.position_info.candidate_link,"readonly":"","label":"Candidate Link"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }