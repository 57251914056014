<template>
    <v-dialog
        v-model="showDialog"
        max-width="600px"
        persistent
    >
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        @click="loadTransitions(item)"
                    >mdi-arrow-right-circle</v-icon>
                </template>
                <span>Transition ticket</span>
            </v-tooltip>
        </template>

        <v-card v-if="nextTransition">
            <v-card-title class="headline">Transition: {{ nextTransition.name }}</v-card-title>

            <v-card-text>
                Are you sure you want to transition {{ item.key }} to <strong>{{ nextTransition.to.name }}</strong>?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showDialog = false">Close</v-btn>
                <v-btn
                    color="blue darken-1"
                    @click="transitionReq(item, nextTransition)"
                    :loading="loading"
                    :disabled="loading"
                    text
                >Transition</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "TransitionDialog",
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            showDialog: false,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            issueTransitions: 'population/getIssueTransitions'
        }),
        nextTransition() {
            return this.issueTransitions.find(t => t.name === 'Submit to Finance' || t.name === 'Submit to HR' || t.name == 'Onboarding');
        }
    },
    methods: {
        ...mapActions({
        }),
        async loadTransitions(item) {
            await this.$store.dispatch('population/loadIssueTransitions', item.key);
            if (!this.nextTransition) {
                this.showDialog = false;
                this.$store.commit('setErrorSnack', `You are not authorized to any transitions for ${item.key}`);
            }
        },
        async transitionReq(item, nextTransition) {
            this.loading = true;
            await this.$store.dispatch('population/transitionIssue', {issue: item.key, transitionTo: nextTransition});
            this.loading = false;
        }
    },
    mounted() {
    }
};
</script>