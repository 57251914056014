<template>
    <v-container>
        <v-btn
            v-if="newCandidate.status !== 'Interviewing' || newCandidate.status !== 'Department Hosting'"
            absolute
            fab
            top
            right
            class="mt-12 mr-6"
            small
            @click="changeEditMode"
        >
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-row>
            <v-col xs12 md4 class="pt-0 pb-0">
                <v-text-field
                    v-model="newCandidate.firstName"
                    label="First name"
                    :disabled="!allowEdit"
                    :rules="$rules.required"
                    @change="autoSave"
                />
            </v-col>
            <v-col xs12 md4 class="pt-0 pb-0">
                <v-text-field
                    v-model="newCandidate.middleName"
                    :disabled="!allowEdit"
                    label="Middle name"
                    @change="autoSave"
                />
            </v-col>
            <v-col xs12 md4 class="pt-0 pb-0">
                <v-text-field
                    v-model="newCandidate.lastName"
                    label="Last name"
                    :disabled="!allowEdit"
                    :rules="$rules.required"
                    @change="autoSave"
                />
            </v-col>
        </v-row>
        <v-text-field
            v-model="newCandidate.personalEmail"
            label="Personal e-mail"
            :disabled="!allowEdit"
            :rules="$rules.email"
            validate-on-blur
            @change="autoSave"
            hint="Please use CUMC email if current employee"
        />
        <v-text-field
            v-model="newCandidate.contactInfo"
            label="Phone number"
            :disabled="!allowEdit"
            :rules="$rules.phoneNumber"
            @change="autoSave"
            hint="Please use a work phone number or manager's phone number"
        />
        <v-row v-if="employeeType.name == 'Service Corp Xpress'">
            <v-col xs12 md6 class="pt-0 pb-0">
                <v-text-field
                    v-model="newCandidate.cuUni"
                    label="CU UNI"
                    :disabled="!allowEdit"
                    :rules="$rules.required"
                    @change="autoSave"
                />
            </v-col>
            <v-col xs12 md6 class="pt-0 pb-0">
                <v-text-field
                    v-model="newCandidate.cwid"
                    label="CWID"
                    :disabled="!allowEdit"
                    :rules="$rules.required"
                    @change="autoSave"
                />
            </v-col>
        </v-row>
        <v-select
            v-model="newCandidate.recruitSrc"
            :items="recruitSrcOptions"
            label="Recruitment source"
            :disabled="!allowEdit"
            :rules="$rules.required"
            @change="resetRecruitSrc"
        />
        <v-select
            v-if="newCandidate.recruitSrc === 'org'"
            v-model="newCandidate.recruitSrcDept"
            :disabled="!allowEdit"
            :items="departmentList"
            item-text="name"
            label="Source department"
            :rules="$rules.required"
            return-object
            @change="autoSave"
        />
        <v-select
            v-if="newCandidate.recruitSrc === 'dept'"
            v-model="newCandidate.recruitSrcDiv"
            :disabled="!allowEdit"
            :items="divisionList"
            item-text="long_name"
            label="Source division"
            :rules="$rules.required"
            return-object
            @change="autoSave"
        />
        <v-currency-field
            v-if="showFinancial"
            v-model="newCandidate.startingSalary"
            label="Starting salary"
            prefix="$"
            :disabled="(newCandidate.status !== 'Interviewing' && newCandidate.status !== 'Department Hosting') && !(((newCandidate.status === 'HR Review' && ['Faculty', 'Fellow'].indexOf(newCandidate.hireType) > -1) || (newCandidate.status === 'HR Clearance' && newCandidate.hireType === 'Resident')) && editMode)"
            :rules="$rules.required"
            :hint="salaryHint"
        />
        <v-card class="mt-2">
            <v-card-title class="subtitle-1" style="cursor: pointer" @click="showAttachments = !showAttachments">
                <v-icon>mdi-paperclip</v-icon>
                {{ attachmentsHeader }}
                <v-spacer></v-spacer>
                <v-btn icon>
                    <v-icon>{{ showAttachments ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-title>
            <v-expand-transition>
                <div v-show="showAttachments">
                    <v-divider></v-divider>
                    <v-card-text>
                        <AttachmentAlert
                            :attachments="jiraAttachments"
                            :pendingAttachments="newCandidate.pendingAttachments"
                            :attachmentTypes="hrTicketType.attachments"
                            :conditionalModel="newCandidate"
                            conditionalModelName="newCandidate"
                            ref="attAlert"
                            @validityChange="changeAttachmentValidity"
                            conditional-only
                            hide-on-valid
                        />
                        <AttachmentList
                            :attachments="jiraAttachments"
                            :item="{key: newCandidate.jiraKey}"
                            :attachmentTypes="hrTicketType.attachments"
                            :isValidPending="validAttach"
                            :showTitle="false"
                            @deleteAttachment="updateAttachments"
                            @changeAttachmentType="updateAttachments"
                        />
                        <AttachmentInput
                            :attachmentTypes="requiredAttachments"
                            :defaultAttachmentType="defaultAttachmentType"
                            @pendingAttachments="handlePendingAttachments"
                            :rules="[validAttach]"
                            ref="attInput"
                        />
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import AttachmentAlert from '@/components/population/attachments/AttachmentAlert';
import AttachmentList from '@/components/population/attachments/AttachmentList';
import AttachmentInput from '@/components/population/attachments/AttachmentInput';


export default {
    name: "CandidateInfoForm",
    components: {
        AttachmentAlert,
        AttachmentList,
        AttachmentInput
    },
    props: {
        showFinancial: {
            type: Boolean,
            default: false
        },
        isValid: {
            type: Boolean,
            default: true
        },
        initialLoad: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editMode: false,
            validAttach: true,
            pendingAttachments: [],
            attachmentIssues: [],
            showAttachments: false,
            timeout: null,
            saving: false
        };
    },
    computed: {
        ...mapGetters({
            departmentList: 'population/getDepartmentList',
            divisionList: 'population/getDivisionList',
            newCandidate: 'population/getNewCandidate',
            employeeType: 'population/getEmployeeType',
            divPerms: 'population/getDivPerms'
        }),
        allowEdit() {
            return this.newCandidate.status === "Interviewing" || this.newCandidate.status === 'Department Hosting' || this.editMode ? true : false;
        },

        salaryHint() {
            if (this.newCandidate.minSalary && this.newCandidate.maxSalary)
                return `Position range: $${this.newCandidate.minSalary}-$${this.newCandidate.maxSalary}`;
            return '';
        },
        hrTicketType() {
            if (this.employeeType.hr_ticket_type) {
                return this.employeeType.hr_ticket_type;
            }
            return {attachments: {}};
        },
        jiraAttachments() {
            if (this.newCandidate.attachments.jira) {
                return this.newCandidate.attachments.jira;
            }
            return []
        },
        requiredAttachments() {
            const atts = Object.keys(this.hrTicketType.attachments).filter(k => this.attachmentIssues.some(x => x.key === k));
            if (atts.length) {
                // if there are required attachments, limit selection to just those
                return atts.reduce((o, kk) => {
                    o[kk] = this.hrTicketType.attachments[kk]; return o; 
                }, {});
            } else {
                // show all attachment types (for future edits, i.e. replacing a CV)
                return this.hrTicketType.attachments;
            }
        },
        defaultAttachmentType() {
            const k = Object.keys(this.requiredAttachments);
            return k.length === 1 ? this.requiredAttachments[k[0]].attachment_name : null;
        },
        attachmentsHeader() {
            if (this.jiraAttachments.length > 1) {
                return `${this.jiraAttachments.length} Attachments`;
            } else if (this.jiraAttachments.length === 1) {
                return '1 Attachment';
            } else {
                return 'Attachments';
            }
        },
    },
    watch: {
        'newCandidate.recruitSrc': {
            handler: function(){
                this.$refs.attAlert.validate();
            },
            deep: true
        },
    },
    methods: {
        handleFile(e, item) {
            let data = {};
            data[item] = e;
            this.$store.dispatch("population/setNewCandidateAttachments", data);
        },
        changeEditMode() {
            this.editMode = !this.editMode;
            this.$emit("changeEditMode", this.editMode);
        },
        resetRecruitSrc() {
            const can = this.newCandidate;
            switch (can.recruitSrc) {
                case "org":
                    delete can.recruitSrcDiv;
                    break;
                case "dept":
                    delete can.recruitSrcDept;
                    break;
                default:
                    delete can.recruitSrcDiv;
                    delete can.recruitSrcDept;
            }
            this.resetRecruitType();
        },
        resetRecruitType() {
            if (this.newCandidate.recruitType) delete this.newCandidate.recruitType;
            if (this.newCandidate.recruitTypeNote) delete this.newCandidate.recruitTypeNote
        },
        handlePendingAttachments(payload) {
            this.newCandidate.pendingAttachments = payload;
        },
        updateAttachments(payload) {
            this.newCandidate.attachments.jira = payload.jiraAttachments;
            this.$nextTick(() => {
                this.$refs.attAlert.validate();
            });
        },
        changeAttachmentValidity(isValid, attachmentIssues) {
            this.validAttach = isValid;
            this.attachmentIssues = attachmentIssues;
            if (!isValid) {
                this.showAttachments = true;
            }
        },
        attachmentsUploaded() {
            this.showAttachments = false;
            this.$refs.attInput.reset();
        },
        async autoSave() {
            if (this.initialLoad) return;

            this.resetRecruitType();
            
            if (this.timeout) clearTimeout(this.timeout);
            
            this.timeout = setTimeout(async () => {                    
                this.$emit('changeAutoSaving');
                await this.$store.dispatch('population/submitNewCandidate', {'autoSave': true});
                this.$refs.attInput.reset();
                this.$emit('changeAutoSaving');
            }, 5000);
        },
    }
};
</script>